 /* Reset */
 *{  
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


/* Main Styling */
html  body{
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
line-height: 1,7em;
}
a{
  color:#333;
  text-decoration: none;
}
h1,h2,h3 {
  padding-bottom:20px;
}

p{
  margin:10px 0;
}

/* Utility Classes */
.container{
  margin:auto;
  max-width: 1100px;
  overflow:auto;
  padding:0 20px;
}

.text-primary{
  color:#f7c08a;
}

.lead{
  font-size:20px;
}

.btn{
  display:inline-block;
  font-size:18px;
  color:#fff;
  background:#333;
  padding:13px 20px;
  border:none;
  cursor:pointer;
}

.btn:hover{
  background:#f7c08a;
  color:#333
}
.btn-light {
  background: #f4f4f4;
  color: #333;
}

.bg-dark {
  background: #333;
  color: #fff;
}

.bg-light {
  background: #f4f4f4;
  color: #333;
}

.bg-primary {
  background: #f7c08a;
  color: #333;
}
.clr{
    clear:both;
}
.l-heading{
    font-size:40px;
    text-align: center;
    padding-top: -0.5rem;
}

/* Padding */
.py-1{padding:10px 0 ;}
.py-2{padding:20px 0 ;}
.py-3{padding:30px 0 ;}

/* Navbar */
.img-logo {
  height: 4rem;
  width: 5rem;
}
/* 
.link-navbar {
  margin-top: 1rem;
} */

#navbar{
  background:#2c2c2c;
  color:#fff;
  overflow:auto;
  padding-top: 2rem;
}

#navbar a{
  color:#fff
}

#navbar .container {
  display: flex;
  justify-content: space-between;
}

.link-navbar {
  display: flex;
}

.current-link {
  color:#f7c08a;
}

.link-navbar li {
  padding: 20px;
  list-style: none;
}

@media screen and (max-width: 768px) {
  #navbar {
    height: 11rem;
  }

.logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.navlink-us {
  display: none;
}

.link-navbar {
  position: absolute;
  left: 50%;
  top: 6.5rem;
  font-size: 90%;
  transform: translateX(-50%);
}
}

/* showcase */
#showcase{
  background: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),url('./img/img-hotel/room3.jpg') no-repeat center center/cover;
  height: 585px;
}

#showcase .showcase-content{
  color:#fff;
  text-align:center;
  padding-top:200px;
}

#showcase .showcase-content h1{
  font-size:75px;
  line-height:1.2em;
}

#showcase .showcase-content p {
  padding-bottom:20px;
  line-height: 1.7em;
}
#home-info {
  height: 400px;
}

#home-info .info-img {
  float: left;
  width: 50%;
  background: url('./img/img-hotel/hotelOverview.jpg') no-repeat;
  min-height: 100%;
}

#home-info .info-content {
  float: right;
  width: 50%;
  height: 100%;
  text-align: center;
  padding: 50px 30px;
  overflow: hidden;
  
}

.hotel-desc-index {
  text-align: justify;
}

#home-info .info-content p {
  padding-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .showcase-content {
    margin-top: -8rem;
  }

  .info-content h2 {
    font-size: 1rem;
    margin-top: -2rem;
  }

  .info-content .btn-light {
    display: none;
  }

  .hotel-desc-index {
    font-size: 0.6rem;
  }
}

/* Features */
.box{
  float:left;
  widows: 33.5%;
  padding:60px;
  text-align:center;
}

.box i{
  margin-bottom:10px;
}

/* About Info */
.hotel-about-desc {
  text-align: justify;
  margin-right: 3rem;
  font-size: 1.2rem;
}

#about-info .info-right{
  float:right;
  width:50%;
  min-height:100%;
}
#about-info .info-left{
  float:left;
  width:50%;
  min-height: 100%;
}

#about-info .info-right img{
  display:block;
  margin:auto;
  width: 25rem;
  height: auto;
  border-radius:50%;
}

@media screen and (max-width: 768px) {
  .container {
    margin-left: 2rem;
  }

  #about-info .info-left{
  float: none;
  width: 100%;
}

  #about-info .info-right img{
    width: 15rem;
    margin-left: -9rem;
}
}

#services {
  background-color: #f7f7f7;
  padding: 40px 0;
}

.services-head {
  font-size: 48px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 50px;
}

.services-part {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 2rem;
  max-width: 100%;
}

.services-part div {
  flex: 0 0 calc(50% - 30px);
  margin: 0 15px 30px;
  padding: 40px;
  border: 1px solid #e5e5e5;
  text-align: center;
}

.services-part-third {
  flex-basis: 100%;
}

.services-part div i {
  font-size: 48px;
  margin-bottom: 20px;
}

.service-tag {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  display: inline-block;
  text-transform: uppercase;
}

.service-part-para {
  font-size: 16px;
  line-height: 1.8;
  color: #777;
  text-align: justify;
}

#contact-info {
  background-color: #181717;
}

#contact-form .form-group{
  margin-bottom:20px;
}

#contact-form label{
  display:block;
  margin-bottom:5px;
}

#contact-form input, #contact-form textarea {
  width:100%;
  padding:10px;
  border:1px #ddd slod;
}

#contact-form textarea{
  height:200px;
}
#contact-form input:focus, #contact-form textarea:focus {
  outline:none;
  border-color:#f7c08a
}

.form-group textarea {
  resize: none;
}

.mail-item, .call-item {
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .service-part-para {
    display: none;
  }

  .service-tag {
    font-size: 2rem;
  }

  /* Footer */
  #contact-info .container {
    display: flex;
    flex-direction: column;
  }
}